@font-face {
	font-family: 'Gotham';
	src: url('./assets/gotham-book.woff2');
}

@font-face {
	font-family: 'Gotham-Light';
	src: url('./assets/Gotham-Light.otf');
}

@font-face {
	font-family: 'Gotham-Medium';
	src: url('./assets/Gotham-Medium.otf');
}

@font-face {
	font-family: 'Gotham-Bold';
	src: url('./assets/gotham-bold.otf');
}

@font-face {
	font-family: 'Tungsten';
	src: url('./assets/tungsten-bold.woff2');
}

body {
	margin: 0;
	font-family: 'Gotham', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
